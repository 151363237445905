import { Table, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import { Input } from "../NewRegistration/Input";
import { FORM } from "./constants";
import { Button } from "react-bootstrap";
const keys = [
  "FB_MONTH",
  "FB_TOTAL",
  "FB_YEAR",
  "HGSF_MONTHLY_FEE_BILL_ID",
  "HGSF_STD_MFBILL_ID",
  "breakup",
];

export const ExistingFees = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [feesData, setFeesData] = useState([]);
  const handleScroll = (e) => {
    console.log(e);
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };
  const [form, setForm] = useState({});
  const onChange = (e) => {
    const { value, name } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const onItemClick = ({ fieldName, value }) => {
    setForm((prev) => ({ ...prev, [fieldName]: value }));
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await axios.post(
        `https://cors-fix-kylo.herokuapp.com/https://eschool.appsosindia.org/views/accounts/php/fee_enquiry.php?reg_no=${form.reg_no}&mobile=${form.mobile}&academic_year=${form.academic_year}&school_code=HGBL`
      );
      setLoading(false);
      setResult(result.data.fee_enquiry?.length > 0 ? false : true);
      setFeesData(result.data.fee_enquiry);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }
  function isObj(val) {
    return typeof val === "object";
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  const buildFormForCC = (data) => {
    const form = document.createElement("form");
    form.setAttribute("id", "nonseamless");
    form.setAttribute("name", "redirect");
    form.setAttribute("method", "post");
    form.setAttribute("action", data.url);

    const input = document.createElement("input");
    input.setAttribute("id", "encRequest");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "encRequest");
    input.setAttribute("value", stringifyValue(data["encRequest"]));
    form.appendChild(input);

    const input2 = document.createElement("input");
    input2.setAttribute("id", "access_code");
    input2.setAttribute("type", "hidden");
    input2.setAttribute("name", "access_code");
    input2.setAttribute("value", stringifyValue(data["accessCode"]));
    form.appendChild(input2);
    return form;
  };

  function postCC(details) {
    const form = buildFormForCC(details);
    // console.log(form);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const payment = async (amount, fees_id) => {
    const response = await axios.post(
      "https://ccavenue-kylo.herokuapp.com/api/ccavenue/create/order",
      {
        studentPhone: form.mobile,
        totalAmount: amount,
        schoolCode: "HGBL",
        reg_id: form.reg_no,
        fees_id: fees_id,
        academic_year: form.academic_year,
      }
    );
    postCC(response.data);
  };

  const columns = [
    {
      title: "Month",
      dataIndex: "FB_MONTH",
      key: "FB_MONTH",
    },
    {
      title: "Breakup",
      dataIndex: "breakup",
      key: "breakup",
      render: (text, record) =>
        text.map((item) => (
          <div>
            <Typography.Link>
              {item.FEE_NAME} : {item.FB_AMOUNT}
            </Typography.Link>
          </div>
        )),
    },
    {
      title: "Total",
      dataIndex: "FB_TOTAL",
      key: "FB_TOTAL",
    },
    {
      title: "Year",
      dataIndex: "FB_YEAR",
      key: "FB_YEAR",
    },
    {
      title: "Monthly Id",
      dataIndex: "HGSF_MONTHLY_FEE_BILL_ID",
      key: "HGSF_MONTHLY_FEE_BILL_ID",
    },

    {
      title: "Student Bill Id",
      dataIndex: "HGSF_STD_MFBILL_ID",
      key: "HGSF_STD_MFBILL_ID",
    },
    {
      title: "Payment ",
      key: "Payment",
      dataIndex: "",
      render: (_, record) => (
        <Button
          className="apply-btn cursor"
          onClick={() => payment(record.FB_TOTAL, record.HGSF_STD_MFBILL_ID)}
        >
          Payment
        </Button>
      ),
    },
  ];

  return (
    <div>
      <div className="home_main" onScroll={handleScroll}>
        <Navbar isScrolled={isScrolled} handleClick={handleClick} />
        <NavbarMenu isShow={isShow} handleClick={handleClick} />
        <div className="pt-5 mt-5 flex-center d-flex justify-content-center align-items-center">
          <div>
            <form
              onSubmit={submitForm}
              className="d-flex my-5 justify-content-center align-items-center flex-column"
            >
              <div className="row d-flex justify-content-center">
                {FORM["STEP_1"].map((item) => (
                  <Input
                    value={item.value}
                    readOnly={item.readOnly}
                    key={item.name}
                    {...item}
                    onChange={onChange}
                    form={form}
                    onClick={onItemClick}
                  />
                ))}
              </div>
              <div className="flex-center">
                <Button
                  type="submit"
                  disabled={loading}
                  className="apply-btn cursor my-3 "
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </div>
            </form>
            {result && (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <h5> {result && "No Result Found, Try another !"}</h5>
              </div>
            )}
          </div>
        </div>
        {feesData?.length > 0 && (
          <div className="pt-5  mx-5">
            <Table
              columns={columns}
              dataSource={feesData}
              loading={loading}
              rowKey="HGSF_MONTHLY_FEE_BILL_ID"
            />
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};
