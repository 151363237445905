import React from "react";
import pdf from "../pdf/ip.pdf";
const pdfv = () => {
  return (
    <div>
      <embed src={pdf} type="application/pdf" width="100%" height="600px" />
    </div>
  );
};

export default pdfv;
